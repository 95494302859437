import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import DealRole from '@core/enums/DealRole';
import DealStatus from '@core/enums/DealStatus';
import { ADDRESS_PROPERTIES } from '@core/models/Address';
import Address from '@core/models/Address';
import { dt, isVine } from '@core/utils/Environment';
import { getUniqueKey } from '@core/utils/Generators';
import { isEmail } from '@core/utils/Validation';

import { Alert, Button, Checkbox, Dropdown, MenuItem, Validator } from '@components/dmp';

import Fire from '@root/Fire';

@autoBindMethods
export default class DealUserView extends Component {
  static defaultProps = {
    container: null,
    inline: false,
    noReplace: false,
    placement: 'bottom',
    readonly: false,
    template: false,
    text: null,
    style: null,
    signed: false,
  };

  static propTypes = {
    container: PropTypes.object,
    deal: PropTypes.object.isRequired,
    dealUserKey: PropTypes.string,
    inline: PropTypes.bool,
    noReplace: PropTypes.bool,
    partyID: PropTypes.string,
    placement: PropTypes.string,
    property: PropTypes.string,
    subProperty: PropTypes.string,
    readonly: PropTypes.bool,
    template: PropTypes.bool,
    text: PropTypes.string,
    user: PropTypes.object,
    style: PropTypes.object,
    swapDealUser: PropTypes.object,
    signed: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      // if there are multiple users in this party, allow selection of one for editing
      dealUserKey: null,
      show: false,
      fullName: '',
      email: '',
      org: '',
      title: '',
      address: '',
      addressProperties: {},
      splitAddress: false,
      errorFormFields: [],
      phone: '',
      isMe: false,
    };

    // This is just for a base id for child controls
    this.id = 'du-' + getUniqueKey();
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get self() {
    return this.props.deal.currentDealUser;
  }

  get du() {
    const { deal, dealUserKey, inline } = this.props;
    if (!this.party && !deal) return null;

    // When this is used in inline mode (see ContentSection),
    // we have a party specified, e.g., [@Company], but not a specific DealUser
    // So we need to look one up based on the text
    // There's also an edge case in which there are multiple users within one party,
    // in which case we manage via internal state
    if (inline) {
      if (this.editableUsers.length > 0) {
        const key = this.props.dealUserKey || this.state.dealUserKey || null;
        if (key) return _.find(this.editableUsers, { key });
        else return this.editableUsers[0];
      } else {
        return null;
      }
    }
    // In other cases (non-inline) -- e.g., DealUserBlock, SignatureSection -- specific user is specified in props
    else {
      return _.find(deal.users, { key: dealUserKey });
    }
  }

  get party() {
    return _.find(this.props.deal.parties, { partyID: this.props.partyID });
  }

  get editableUsers() {
    const { deal, user, template, signed } = this.props;
    // if user is not logged in, not part of deal, users are not editable
    if (!deal || !user) return [];

    // if we're on a template, current user can edit all users
    if (template) return deal.users;

    // otherwise ensure current user is on deal
    if (!this.self) return [];

    const users = this.party ? this.party.users : deal.users;

    // filter for the list of editable users
    // user can edit DealUser if user is editor/owner or looking at self,
    // AND if deal is already signed, target user must not be a party
    return _.filter(
      users,
      (du) =>
        (du.uid == this.self.uid || [DealRole.OWNER, DealRole.EDITOR].indexOf(this.self.role) > -1) &&
        (!signed || !du.partyID)
    );
  }

  // Only show "This is me" option if it's selected or not yet assigned,
  // and we're in inline party mode (i.e., not editing users in panel) i.e., don't allow user to check it on both parties
  // Also do not allow a view-only user to see it -- they've been assigned by another user
  get canToggleMe() {
    return (
      this.self &&
      this.party &&
      (!this.self.partyID || this.self == this.du) &&
      [DealRole.OWNER, DealRole.EDITOR].indexOf(this.self.role) > -1
    );
  }

  //disable the email requirement for .vine iframes when the user is a party user.
  //see https://trello.com/c/AuDN9Os6/1834-make-external-user-email-addresses-non-mandatory for explanation
  get disableEmailValidation() {
    return isVine && this.party;
  }

  get canSave() {
    const { fullName, email } = this.state;
    const validateEmail = this.disableEmailValidation || this.validateEmail(email);
    return this.validateName(fullName) && validateEmail;
  }

  hasAddressProperties(addressProperties) {
    return (
      addressProperties &&
      Object.keys(addressProperties).length > 0 &&
      Object.values(addressProperties).some((val) => !!val)
    );
  }

  populate() {
    const du = this.du || {};

    this.setState({
      fullName: du.fullName || '',
      email: du.email || '',
      org: du.org || '',
      title: du.title || '',
      address: du.address || '',
      addressProperties: du.addressProperties || {},
      splitAddress: this.hasAddressProperties(du.addressProperties),
      errorFormFields: [],
      phone: du.phone || '',
      isMe: du.deal ? du.deal.currentDealUser == du : false,
    });
  }

  validateName(name) {
    return !!name.trim();
  }

  validateEmail(email) {
    return isEmail(email.trim());
  }

  // called by dropdown to select which user to edit
  // during inline variable (Party) editing context
  selectDealUser(dealUserKey) {
    this.setState({ dealUserKey }, this.populate);
  }

  toggleMe() {
    // if not me, toggle to on and import profile info if present
    if (!this.state.isMe) {
      const profile = this.props.user.info || {};
      const { fullName, email, org, title, address, phone, addressProperties } = this.state;

      this.setState({
        isMe: true,
        fullName: fullName || profile.fullName || '',
        email: email || profile.email || '',
        org: org || profile.org || '',
        title: title || profile.title || '',
        address: address || profile.address || '',
        addressProperties: this.hasAddressProperties(addressProperties)
          ? addressProperties
          : profile.addressProperties || {},
        phone: phone || profile.phone || '',
      });
    } else {
      // if currently on, toggle off and clear fields to reduce unintentional assignment
      this.setState({ isMe: false });
    }
  }

  handleChange(prop, e) {
    const { value } = e.target;
    if (prop.includes('addressProperties')) {
      const [, addressProp] = prop.split('.');
      if (addressProp) {
        this.setState((prevState) => {
          if (typeof prevState.addressProperties === 'object') {
            return {
              saved: false,
              addressProperties: { ...prevState.addressProperties, [addressProp]: value },
            };
          } else {
            return {
              saved: false,
              addressProperties: { [addressProp]: value },
            };
          }
        });
        return;
      }
    }
    this.setState({ [prop]: value });
  }

  handleEnter(e) {
    if (e.key == 'Enter' && this.canSave) this.save();
  }

  async save() {
    //swapDealUser is the deal user that is going to be removed and replaced in case 2 with the newly added user.
    const { swapDealUser } = this.props;
    let newUserJSON = null;

    const info = {
      fullName: this.state.fullName,
      email: this.state.email.trim() || null,
      org: this.state.org || null,
      title: this.state.title || null,
      address: this.state.address || null,
      addressProperties: this.state.addressProperties || null,
      phone: this.state.phone || null,
      partyID: this.props.partyID,
    };

    if (this.hasAddressProperties(info.addressProperties) || this.hasAddressProperties(this.du?.addressProperties)) {
      info.address = '';
    }

    // Certain conditions (e.g., adding NEW user from SignatureMenu) somehow cause an entire React app crash
    // This seems to be due to the fade-out on hide interacting with re-rendering and component unmount
    // So if we actually hide the component *first* then we can safely make the data update afterwards
    // Plus the app appears even more snappy :-)
    await this.hide();

    // 3 cases:
    // 1) this is me is checked, so use current DU and replace with field values
    if (this.state.isMe) {
      //Handles the case where we assign a new user but check this is me.
      //Needed so we can remove the previous deal user and insert the newly created deal user that is me.
      if (swapDealUser) {
        await Fire.createDealUser(this.self.deal, info, null, swapDealUser);
      } else {
        await Fire.saveDealUser(this.self, info);
      }
    }

    // 2) new user, or previously assigned to me and then unassigned (so create new)
    // this is a weird sub-case where someone assigned to self but then unassigned and resaved
    else if (!this.du || this.du == this.self) {
      newUserJSON = await Fire.createDealUser(this.self.deal, info, null, swapDealUser);
    }
    // 3) already assigned (and not to self), so just update existing values
    else if (this.du) {
      await Fire.saveDealUser(this.du, info);
    }
  }

  async show(e, canEdit, target) {
    const { deal } = this.props;

    if (e) e.stopPropagation();

    if (canEdit) {
      this.populate();

      await this.setState({
        show: true,
        target,
      });

      this.focus();
    }
  }

  async hide() {
    if (this._isMounted) {
      await this.setState({ show: false });
    }
    return Promise.resolve();
  }

  focus() {
    if (!this._isMounted) return;
    const el = ReactDOM.findDOMNode(this.refs.input);
    if (el) el.focus();
  }

  // Prevent mouse events related to showing/hiding popovers from taking whole section into editing
  stop(e) {
    e.stopPropagation();
  }

  renderAddressForm() {
    const { address, addressProperties } = this.state;

    if (!this.hasAddressProperties(addressProperties) && address.length > 0 && !this.state.splitAddress) {
      return (
        <FormControl
          componentClass="textarea"
          value={address}
          placeholder="Address"
          onChange={(e) => this.handleChange('address', e)}
          data-cy="edit-address"
        />
      );
    }

    return (
      <>
        <FormGroup>
          <FormControl
            type="text"
            value={this.state.addressProperties?.line1 || ''}
            placeholder="Address Line 1"
            onChange={(e) => this.handleChange('addressProperties.line1', e)}
            data-cy="address-line-1"
          />
        </FormGroup>
        <FormGroup>
          <FormControl
            type="text"
            value={this.state.addressProperties?.line2 || ''}
            placeholder="Address Line 2"
            onChange={(e) => this.handleChange('addressProperties.line2', e)}
            data-cy="address-line-2"
          />
        </FormGroup>
        <div className="address-properties">
          <FormGroup>
            <FormControl
              type="text"
              value={this.state.addressProperties?.city || ''}
              placeholder="City"
              onChange={(e) => this.handleChange('addressProperties.city', e)}
              data-cy="address-city"
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              type="text"
              value={this.state.addressProperties?.state || ''}
              placeholder="State/Province"
              onChange={(e) => this.handleChange('addressProperties.state', e)}
              data-cy="address-state"
            />
          </FormGroup>
        </div>
        <div className="address-properties">
          <FormGroup>
            <FormControl
              type="text"
              value={this.state.addressProperties?.postalCode || ''}
              placeholder="Zip/Postal Code"
              onChange={(e) => this.handleChange('addressProperties.postalCode', e)}
              data-cy="address-postal-code"
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              type="text"
              value={this.state.addressProperties?.country || ''}
              placeholder="Country"
              onChange={(e) => this.handleChange('addressProperties.country', e)}
              data-cy="address-country"
            />
          </FormGroup>
        </div>
      </>
    );
  }

  render() {
    const { property, deal, noReplace, text, template, readonly, inline, style, subProperty, signed } = this.props;
    const { show } = this.state;

    if (!deal) return null;

    // Note: This component needs a good refactoring, mainly splitting teh Popoverand the inline piece.

    // If we don't we're not mounted, but we're inline w/ a party, render the party name right away.
    if (!this._isMounted && inline && this.party) {
      return this.party.get(property);
    }

    // if we can't find a valid party OR DealUser, there's nothing to render
    if (!this._isMounted || (!this.party && !this.du)) {
      return text;
    }

    const du = deal.currentDealUser;
    // the popover is showable if there are more than 0 editable users
    const canEdit =
      template ||
      (this.self != null &&
        !readonly &&
        !noReplace &&
        (this.editableUsers.length > 0 || [DealRole.OWNER, DealRole.EDITOR].indexOf(this.self.role) > -1) &&
        (!signed || (this.du && !this.du.partyID)) &&
        !(du?.role !== DealRole.OWNER && deal.status.data === DealStatus.SIGNING.data));

    // DealUserView has 2 modes (used in 2 places)
    // 1. inline on contract, where it's rendered as a variable
    // 2. on DealUserManager where we're only interested in the popover editor
    // we can differentiate the latter if there is a DealUser but no party specified
    if (inline && !!this.party) {
      const val = this.party.get(property, true);
      const className = cx(
        'variable',
        'party',
        { 'not-editable': !canEdit },
        { readonly: readonly },
        { locked: !!signed },
        { complete: canEdit && val !== '' },
        { incomplete: canEdit && val === '' }
      );

      const addressSubPropertyLabel = ADDRESS_PROPERTIES.find((prop) => prop.data === subProperty)?.label;

      const defaultPartyProperty = text || this.party.get(property);

      let adddressPartyProperty;
      if (property === 'address' && addressSubPropertyLabel) {
        const addressProperties = this.party.get('addressProperties');
        if (addressProperties && addressProperties instanceof Address) {
          const addressPropertiesPlaceholder = `${this.party.displayName}: Address (${addressSubPropertyLabel})`;
          adddressPartyProperty = addressProperties.getSubPropValue(subProperty) || addressPropertiesPlaceholder;
        } else {
          adddressPartyProperty =
            text || `${this.party.displayName}: Address (${addressSubPropertyLabel})` || this.party.get(property);
        }
      }

      return (
        <>
          <span
            ref="target"
            onMouseDown={this.stop}
            onMouseUp={this.stop}
            onClick={(e) => this.show(e, canEdit)}
            className={className}
            style={style}
          >
            {adddressPartyProperty || defaultPartyProperty}
          </span>
          {this.renderEditor()}
        </>
      );
    } else {
      if (show) {
        return this.renderEditor();
      }

      return null;
    }
  }

  renderEditor() {
    const { inline } = this.props;
    const { show, fullName, email, org, title, address, isMe, phone, splitAddress, addressProperties } = this.state;
    const showUserSelect = !!this.party;
    const isEmailLocked = _.get(this.du, 'isEmailLocked');

    return (
      <Modal
        className="modal-user-info"
        dialogClassName="team-creator deal-user-modal-view"
        show={show}
        onHide={this.hide}
        onMouseDown={this.stop}
        onMouseUp={this.stop}
        onClick={this.stop}
        data-cy="modal-user-info"
      >
        <Modal.Header closeButton>
          <span className="headline">{this.party ? this.party.displayName : 'Edit user'}</span>
        </Modal.Header>

        <Modal.Body>
          {showUserSelect && this.editableUsers.length > 1 && inline && (
            <FormGroup>
              <Dropdown id={'dd-' + this.id} title={this.du.fullName} onSelect={this.selectDealUser} block>
                {this.editableUsers.map((u, idx) => (
                  <MenuItem key={idx} eventKey={u.key}>
                    {u.fullName}
                  </MenuItem>
                ))}
              </Dropdown>
            </FormGroup>
          )}
          <FormGroup className="dmp-validator-container">
            <ControlLabel>Name</ControlLabel>
            <FormControl
              type="text"
              value={fullName}
              placeholder="Full Name (required)"
              ref="input"
              onKeyDown={this.handleEnter}
              onChange={(e) => this.handleChange('fullName', e)}
              data-cy="edit-name"
            />
            <Validator
              validateEmpty
              value={fullName}
              validate={this.validateName}
              onResult={_.noop}
              validTip="Valid name"
              invalidTip="Full name is required"
            />
          </FormGroup>

          <FormGroup className="dmp-validator-container">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              type="text"
              value={email}
              placeholder="Email (required)"
              onKeyDown={this.handleEnter}
              onChange={(e) => this.setState({ email: e.target.value })}
              disabled={isEmailLocked}
              data-cy="edit-email"
            />
            {!this.disableEmailValidation && (
              <Validator
                validateEmpty
                value={email}
                validate={this.validateEmail}
                onResult={_.noop}
                disabled={isEmailLocked}
                validTip="Valid email"
                invalidTip="Valid email is required"
                disabledTip={`This email address is securely linked to this ${dt} and cannot be changed. To use a different email, add a new user.`}
              />
            )}
          </FormGroup>

          <FormGroup>
            <ControlLabel>Company</ControlLabel>
            <FormControl
              type="text"
              value={org}
              placeholder="Name of Company"
              onKeyDown={this.handleEnter}
              onChange={(e) => this.handleChange('org', e)}
              data-cy="edit-company"
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Title</ControlLabel>
            <FormControl
              type="text"
              value={title}
              placeholder="Title at Company"
              onKeyDown={this.handleEnter}
              onChange={(e) => this.handleChange('title', e)}
              data-cy="edit-title-company"
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Address</ControlLabel>
            <div className="address-input">
              {this.renderAddressForm()}
              {!this.hasAddressProperties(addressProperties) && address.length > 0 && !splitAddress && (
                <Alert dmpStyle="warning" className="split-address-alert">
                  Split address to individual fields for greater control across team & templates.
                  <a
                    onClick={() => {
                      this.setState((prevState) => {
                        return {
                          splitAddress: !prevState.splitAddress,
                          addressProperties: { ...prevState.addressProperties, line1: prevState.address },
                        };
                      });
                    }}
                    data-cy="convert-address"
                  >
                    Convert
                  </a>
                </Alert>
              )}
            </div>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Phone</ControlLabel>
            <FormControl
              type="text"
              value={phone}
              placeholder="Phone Number"
              onKeyDown={this.handleEnter}
              onChange={(e) => this.handleChange('phone', e)}
              data-cy="edit-phone-number"
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <FormGroup className={`actions ${this.canToggleMe ? `me-action` : ''}`}>
            {this.canToggleMe && (
              <Checkbox id={'check-me-' + this.id} checked={isMe} onChange={this.toggleMe}>
                This is me
              </Checkbox>
            )}
            <div>
              <Button
                onClick={() => {
                  this.setState({ splitAddress: false, errorFormFields: [] });
                  this.hide();
                }}
                data-cy="btn-cancel"
              >
                Cancel
              </Button>
              <Button
                dmpStyle="primary"
                className="save"
                disabled={!this.canSave}
                onClick={this.save}
                data-cy="btn-save"
              >
                Save
              </Button>
            </div>
          </FormGroup>
        </Modal.Footer>
      </Modal>
    );
  }
}
